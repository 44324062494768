<template>
  <div>
    <XyzTransitionGroup
      appear
      class="item-group"
      xyz="fade left-3"
    >
      <b-card key="1">
        <XyzTransition
          appear
          xyz="fade up-3 delay-7"
        >
          <b-row class="mb-50">
            <b-col
              cols="12"
              xl="3"
              class="mb-1"
            >
              <v-select
                v-model="dryReport.dryReportFilter.selectedSort"
                class="mr-1 w-100"
                label="label"
                :options="dryReport.dryReportSortOptions"
                placeholder="Sortir"
                :clearable="false"
                @option:selected="fetchDryReport()"
              />
            </b-col>
            <b-col
              cols="12"
              xl="3"
              class="mb-1 pl-1 pl-xl-0"
            >
              <b-form-input
                v-model="dryReport.dryReportFilter.search"
                class="w-100"
                placeholder="Cari data"
                debounce="500"
              />
            </b-col>
            <b-col
              cols="12"
              xl="5"
              md="12"
              class="d-flex ml-0 ml-xl-auto align-items-start mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar.sidebar-right
                variant="outline-primary"
                class="mr-1"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="16"
                />
                Filter
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'adddryreport' }"
                variant="primary"
                class="flex-grow-1"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Dry Report Baru
              </b-button>
            </b-col>
            <b-col
              v-if="filterMode"
              cols="12"
              class="mb-1"
            >
              <b-badge
                variant="primary"
                class="align-self-center mr-50 cursor-pointer"
                @click="resetFilter"
              >
                Reset Filter
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>
            </b-col>
          </b-row>
        </XyzTransition>
        <XyzTransition
          appear
          xyz="fade down-3 delay-5"
        >
          <b-overlay
            :show="dryReport.loadingDryReport"
          >
            <dry-report-table
              :key="dryReport.dryReportList.toString()"
              :data="dryReport.dryReportList"
              :count="dryReport.dryReportCount"
              @refetch="fetchDryReport()"
            />
          </b-overlay>
        </XyzTransition>
      </b-card>
    </XyzTransitionGroup>

    <b-sidebar
      id="sidebar-right"
      ref="sidebarRight"
      title="Filter"
      right
      shadow
      backdrop
      backdrop-variant="transparent"
      class="sidebar"
      @shown="fetchSomeStuff()"
      @hidden="fetchDryReport()"
    >
      <div class="px-2 py-1">
        <div class="d-flex justify-content-between align-items-end">
          <label>Tanggal Dibuat</label>
          <feather-icon
            v-if="dryReport.dryReportFilter.created_at.startDate ? true : false"
            :key="key"
            size="16"
            icon="XCircleIcon"
            class="text-primary cursor-pointer"
            @click="dryReport.dryReportFilter.created_at = {}; $refs.dateRange.reset()"
          />
        </div>
        <date-range-picker
          ref="dateRange"
          v-model="dryReport.dryReportFilter.created_at"
          :wrap="true"
          class="mb-1"
        />
        <div class="d-flex justify-content-between align-items-end">
          <label>Tanggal Dimulai - Berakhir</label>
          <feather-icon
            v-if="dryReport.dryReportFilter.event_date.startDate ? true : false"
            :key="key"
            size="16"
            icon="XCircleIcon"
            class="text-primary cursor-pointer"
            @click="dryReport.dryReportFilter.event_date = {}; $refs.dateRange2.reset()"
          />
        </div>
        <date-range-picker
          ref="dateRange2"
          v-model="dryReport.dryReportFilter.event_date"
          :wrap="true"
          class="mb-1"
        />
        <label>Lokasi</label>
        <v-select
          v-model="dryReport.dryReportFilter.location"
          :options="dryReport.locationOptions"
          placeholder="Lokasi"
          multiple
          class="mb-1"
          label="name"
        />
        <label>Dibuat Oleh</label>
        <div
          v-if="loadingUser"
          class="d-flex"
        >
          <b-skeleton
            width="100%"
            height="40px"
            class="mb-0"
          />
        </div>
        <v-select
          v-else
          v-model="dryReport.dryReportFilter.userCreate"
          class="w-100"
          label="name"
          :options="user"
          placeholder="Dibuat Oleh"
          multiple
        >
          <template #option="data">
            <div class="d-flex">
              <b-avatar
                :text="avatarText(data.name)"
                variant="light-info"
                class="mr-50"
                size="36"
                :src="data.photo ? data.photo : null"
              />
              <div>
                <span class="font-weight-bold d-block">{{ data.name }}</span>
                <small class="d-block">{{ data.role.name }}</small>
              </div>
            </div>
          </template>
        </v-select>
      </div>
      <template #footer>
        <div class="px-2 py-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="secondary"
            @click="resetFilter(); $refs.sidebarRight.hide()"
          >
            Reset
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput, BRow, BCol, BOverlay, BSidebar, VBToggle, BBadge,
  BSkeleton, BAvatar,
} from 'bootstrap-vue'
import DryReportTable from '@/views/dryreport/DryReportTable.vue'
import vSelect from 'vue-select'
import { createHelpers } from 'vuex-map-fields'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import { mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'

const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    DryReportTable,
    BRow,
    BCol,
    BOverlay,
    BSidebar,
    BBadge,
    BSkeleton,
    BAvatar,
    vSelect,
    DateRangePicker,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  computed: {
    ...mapFields({
      dryReport: 'dryReport',
      dryReportFilter: 'dryReport.dryReportFilter',
    }),
    filterMode() {
      if (this.dryReportFilter.search) return true
      if (this.dryReportFilter.selectedSort.field !== 'created_at' || this.dryReportFilter.selectedSort.sortType !== 'desc') return true
      if (this.dryReportFilter.created_at.startDate) return true
      if (this.dryReportFilter.event_date.startDate) return true
      if (this.dryReportFilter.location.length) return true
      if (this.dryReportFilter.userCreate.length) return true
      return false
    },
    ...mapGetters({
      user: 'getUserList',
      loadingUser: 'getLoadingUser',
    }),
  },
  watch: {
    dryReportFilter: {
      handler() {
        if (!this.$refs.sidebarRight.isOpen) {
          this.fetchDryReport()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (!this.dryReport.dryReportList.length) {
      setTimeout(() => {
        this.fetchDryReport()
      }, 1000)
    }
  },
  methods: {
    avatarText,
    resetFilter() {
      this.dryReport.dryReportFilter = {
        search: '',
        selectedSort: {
          label: 'Tanggal Dibuat ↑',
          field: 'created_at',
          sortType: 'desc',
        },
        offset: 1,
        limit: 25,
        created_at: {},
        event_date: {},
        location: [],
        userCreate: [],
      }

      this.fetchDryReport()
    },
    async fetchDryReport() {
      this.dryReport.loadingDryReport = true
      const search = this.dryReportFilter.search ? this.dryReportFilter.search : null
      const createdAt = this.dryReportFilter.created_at.startDate ? {
        date_min: new Date(Date.parse(this.dryReportFilter.created_at.startDate)).toISOString().slice(0, 10),
        date_max: new Date(Date.parse(this.dryReportFilter.created_at.endDate)).toISOString().slice(0, 10),
      } : null
      const eventDate = this.dryReportFilter.event_date.startDate ? {
        date_min: new Date(Date.parse(this.dryReportFilter.event_date.startDate)).toISOString().slice(0, 10),
        date_max: new Date(Date.parse(this.dryReportFilter.event_date.endDate)).toISOString().slice(0, 10),
      } : null
      const location = this.dryReportFilter.location.length ? [this.dryReportFilter.location.map(el => el.id).join(', ')] : null
      const userCreate = this.dryReportFilter.userCreate.length ? [this.dryReportFilter.userCreate.map(el => el.id).join(', ')] : null
      this.$store.dispatch('getDryReports', {
        filter: {
          search,
          sort: this.dryReportFilter.selectedSort.field,
          sortType: this.dryReportFilter.selectedSort.sortType,
          created_at: createdAt,
          event_date: eventDate,
          location,
          userCreate,
        },
        pagination: {
          limit: this.dryReportFilter.limit,
          offset: this.dryReportFilter.offset - 1,
        },
      }).then(result => {
        this.dryReport.dryReportList = result.dry_reports
        this.dryReport.dryReportCount = result.count
        this.dryReport.loadingDryReport = false
      }).catch(() => {
        this.dryReport.loadingDryReport = false
      })
    },
    fetchSomeStuff() {
      if (!this.user.length) {
        this.$store.dispatch('getAllUser', { filter: null })
      }

      if (!this.dryReport.locationOptions.length) {
        this.$store.dispatch('getLocations').then(result => {
          this.dryReport.locationOptions = result
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/components/variables-dark';

.b-sidebar {
  background-color: #fff !important;

  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  width: 360px;
}

.form-control {
  .dark-layout & {
    color: #fff !important;
  }
}
</style>
