<template>
  <div>
    <!-- eslint-disable vue/attribute-hyphenation -->
    <vue-good-table
      ref="vgt"
      :columns="columns"
      :rows="data"
      :pagination-options="{
        enabled: true,
        perPage:dryReport.dryReportFilter.limit
      }"
      styleClass="vgt-table"
    >
      <empty-state-table slot="emptystate" />

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'name'">
          <h6
            v-if="props.row.file"
            class="m-0 text-primary cursor-pointer"
            @click="$refs.pdf.show(props.row.file)"
          >
            {{ props.row.file.substring(props.row.file.lastIndexOf('/') + 1) }}
          </h6>
          <h6
            v-else
            class="m-0 text-muted font-weight-normal font-italic"
          >
            Tidak Ada File Dry Report
          </h6>
          <small class="text-secondary">
            {{ props.row.event_start }} {{ props.row.event_start && props.row.event_end ? '-' : '' }} {{ props.row.event_end }}
          </small>
        </span>
        <span v-else-if="props.column.field === 'vol_efficiency'">
          <div class="d-flex justify-content-between mb-25">
            <small class="text-muted">
              Volume
            </small>
            <small class="text-secondary">
              {{ props.row.vol_efficiency ? `${props.row.vol_efficiency * 100} %` : 0 }}
            </small>
          </div>
          <b-progress
            :value="props.row.vol_efficiency"
            :max="1"
            class="mb-50"
          />
          <div class="d-flex justify-content-between mb-25">
            <small class="text-muted">
              DFT
            </small>
            <small class="text-secondary">
              {{ props.row.dft_efficiency ? `${props.row.dft_efficiency * 100} %` : 0 }}
            </small>
          </div>
          <b-progress
            :value="props.row.dft_efficiency"
            :max="1"
          />
        </span>
        <span v-else-if="props.column.field === 'created_at'">
          <h6 class="m-0">
            {{ formatDate(props.row.created_at) }}
          </h6>
        </span>
        <span v-else-if="props.column.field === 'userCreate'">
          <b-media class="mt-25">
            <template #aside>
              <b-avatar
                size="36"
                :text="avatarText(props.row.userCreate.name)"
                variant="light-primary"
                :src="props.row.userCreate.photo && props.row.userCreate.photo !== 'default.png' ?
                  `${$restHTTP}/assets/img/profile-picture/${props.row.userCreate.id}/${props.row.userCreate.photo}` :
                  `${$restHTTP}/assets/img/profile-picture/default.png`
                "
              />
            </template>
            <router-link :to="{ name: 'user-detail', params: {id: props.row.userCreate.id.toString()} }">
              <h6 class="text-primary m-0">{{ props.row.userCreate.name }}</h6>
            </router-link>
            <small
              v-if="props.row.userCreate.role"
              class="d-block text-muted"
            >
              {{ props.row.userCreate.role.name }}
            </small>
          </b-media>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            offset="-100"
            boundary="window"
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              :disabled="!resolveUserPermission(props.row.userCreate.id)"
              @click="$router.push({ name: 'edit-dryreport', params: {id: props.row.id} })"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                :class="!resolveUserPermission(props.row.userCreate.id) ? 'text-muted' : 'text-warning'"
              />
              <span :class="!resolveUserPermission(props.row.userCreate.id) ? 'text-muted' : 'text-warning'">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="!resolveUserPermission(props.row.userCreate.id)"
              @click="deleteDryReport(props.row.id)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
                :class="!resolveUserPermission(props.row.userCreate.id) ? 'text-muted' : 'text-danger'"
              />
              <span :class="!resolveUserPermission(props.row.userCreate.id) ? 'text-muted' : 'text-danger'">Hapus</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Lihat 1 hingga
            </span>
            <b-form-select
              v-model="dryReport.dryReportFilter.limit"
              :options="[25, 50, 100, 200]"
              class="mx-1"
            />
            <span class="text-nowrap "> dari total {{ count }} data </span>
          </div>
          <div>
            <b-pagination
              v-model="dryReport.dryReportFilter.offset"
              :total-rows="count"
              :per-page="dryReport.dryReportFilter.limit"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>
import {
  BFormSelect, BPagination, BProgress, BDropdown, BDropdownItem, BMedia, BAvatar,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText, formatCurrency, formatDate } from '@core/utils/filter'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import { createHelpers } from 'vuex-map-fields'
import PdfPreview from '@/components/misc/PdfPreview.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  components: {
    BFormSelect,
    BPagination,
    BProgress,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    VueGoodTable,
    EmptyStateTable,
    PdfPreview,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarText,
      formatCurrency,
      columns: [
        {
          field: 'name',
          label: 'Dry Report',
          sortable: false,
          tdClass: 'w-vgt-240',
        },
        {
          field: 'vol_efficiency',
          label: 'Efisiensi',
          sortable: false,
          tdClass: 'w-vgt-220',
        },
        {
          field: 'created_at',
          label: 'Tanggal Dibuat',
          sortable: false,
          width: '200px',
        },
        {
          field: 'userCreate',
          label: 'Dibuat Oleh',
          sortable: false,
          width: '260px',
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapFields([
      'dryReport',
    ]),
  },
  mounted() {
    this.$refs.vgt.currentPage = this.dryReport.dryReportFilter.offset
  },
  methods: {
    formatDate,
    deleteDryReport(id) {
      this.$swal({
        title: 'Hapus dry report?',
        text: 'Konfirmasi jika anda ingin menghapus dry report',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteDryReport', id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dry report berhasil dihapus!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$emit('refetch')
          })
        }
      })
    },
    resolveUserPermission(idUser) {
      return (this.$root.role === 2 || idUser === this.$store.getters.getCurrentUser.user.id)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
body.dark-layout .badge {
  color: #fff !important;
}
</style>
