<template>
  <date-range-picker
    ref="picker"
    :key="keyInput"
    v-model="datedata"
    class="w-100"
    :class="{ 'wrap-calendar' : wrap }"
    :locale-data="locale"
    :ranges="customRange ? customRange : range"
    v-bind="$attrs"
    placeholder="Tanggal"
    :opens="opens"
    :max-date="maxDate"
    :min-date="minDate"
    @startSelection="$emit('startSelection', $event)"
    @update="$emit('update', $event)"
  />
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import '@/assets/css/vue-daterange-picker.scss'

export default {
  components: {
    DateRangePicker,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    opens: {
      type: String,
      default: 'center',
    },
    customRange: {
      type: Object,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      locale: {
        format: 'dd/mm/yyyy',
        applyLabel: 'Simpan',
        cancelLabel: 'Tutup',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      keyInput: '',
    }
  },
  computed: {
    datedata: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        // re render input
        setTimeout(() => {
          if (val > this.value) {
            this.keyInput = Math.random()
          }
        }, 500)
      },
    },
    range() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999)
      const yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0)
      const yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(11, 59, 59, 999)
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999)
      /* eslint-disable quote-props */
      return {
        'Hari ini': [today, todayEnd],
        'Kemarin': [yesterdayStart, yesterdayEnd],
        'Bulan ini': [thisMonthStart, thisMonthEnd],
        'Tahun ini': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)],
        'Bulan lalu': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)],
      }
    },
  },
  methods: {
    reset() {
      this.$refs.picker.end = null
      this.$refs.picker.start = null
    },
  },
}
</script>

<style lang="scss">
  .wrap-calendar {
    .daterangepicker .calendars-container[data-v-4391f606] {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
    }

    .daterangepicker .drp-selected {
      display: block;
      font-size: 12px;
      padding-right: 0px;
      margin-bottom: 8px;
    }
  }
</style>
